@import url("https://fonts.googleapis.com/css2?family=Poppins");

body {
  background: #f1f2f4;
  margin: 0;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.top-mil-box {
  
  width: 100%;
}
.top-mil-box > div{
  width: 50%;
  
}
.side-Mill {
  display: inline-block;
  
  
}

.hero-section {
  height: 500px;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://s3-eu-west-1.amazonaws.com/initiafy-website-images/fotoliabig-data.jpg);
  background-size: cover;
  color: white;
}

.hero-section > h2 {
  font-family: Poppins; 
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20%;
}

.sales-copy-bs {
  height: 250px;
  width: 100%;
}

.sales-copy-bs > h2 {
  color: #133588;
  font-family: Poppins; 
}

.sales-copy-bs > typo

.sales-copy-bs > div {
  display: flex;
 justify-content: space-around;
  width: 30%;
  margin: 0 auto; 
}

.block-thing {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 30%;
  color: "black";
  height: 50%;
}

.block-thing > img {
  width: 50%; 
  height: 100%; 
}

.sub-block > p {
  font-family: Poppins;
  display: inline-flex;
  justify-content: "space-around";
  margin-left: 10;
  text-align: left;
  font-size: 18px;
}

